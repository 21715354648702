const data = [
  {
    id: 1,
    img: '/img/chilli-consulting-logo.svg',
    alt: 'Chilli Consulting Logo',
    url: 'https://chilli.consulting/',
  },

  {
    id: 2,
    img: '/img/kdr-logo.svg',
    alt: 'KDR Logo',
    url: 'https://chrisscasper.com/kdr-home/',
  },

  {
    id: 3,
    img: '/img/neovision-logo.svg',
    alt: 'Neo Vision Logo',
    url: 'https://neovision.dev/',
  },
]

export default function Partners() {
  return (
    <section className="section-wrapper flex flex-col items-center justify-center space-y-8 mb-16">
      <h1 className="text-title text-secondary">Partners</h1>

      <div className="flex flex-wrap gap-8 items-center justify-center">
        {data.map(item => {
          return (
            <a href={item.url} target={'_blank'} key={item.id}>
              <img
                src={item.img}
                alt={item.alt}
                referrerPolicy={'no-referrer'}
                className="w-64 h-32 py-6 opacity-50 hover:opacity-100 p-4 transition duration-200 ease-in-out"
              />
            </a>
          )
        })}
      </div>
    </section>
  )
}
