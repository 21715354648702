import Modal from 'react-modal'
import LockIcon from '../svgs/LockIcon'

export default function LockedModal({ isOpen, closeModal }) {
  Modal.setAppElement('#root')

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #D02E80',
      maxWidth: '90%',
      padding: '.5rem',
      background: 'rgba(193, 0, 132, 0.25)',
      borderRadius: '15px',
    },
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
    >
      <div className="flex flex-col items-center space-y-10 text-black p-8 max-w-[490px] text-white border border-[#D32C81] rounded-[10px] bg-[#D32C81]">
        <div className="space-y-6 flex flex-col items-center">
          <LockIcon />
          <h1 className="text-5xl">Locked</h1>
        </div>

        <p className="text-xl text-center">
          Join our discord channel to stay in touch with our future projects
        </p>

        <a
          className="my-4 hover:translate-y-[-5px] transition-transform duration-500"
          href="https://discord.com/invite/XvK3w9RpxE"
          target={'_blank'}
        >
          <img
            className="my-4 mx-auto w-full bg-transparent"
            src="/img/DiscordButton.svg"
            alt=""
          />
        </a>
      </div>
    </Modal>
  )
}
