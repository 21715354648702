import PlayIcon from '../svgs/PlayIcon'
import { useState, useRef, useEffect } from 'react'
import PrevIcon from '../svgs/PrevIcon'
import NextIcon from '../svgs/NextIcon'
import ReplayIcon from '../svgs/ReplayIcon'
import ShuffleIcon from '../svgs/ShuffleIcon'
import MusicPlayIcon from '../svgs/MusicPlayIcon'
import VolumeIcon from '../svgs/VolumeIcon'
import ReactPlayer from 'react-player'
import MuteIcon from '../svgs/MuteIcon'
import ClosePlayerIcon from '../svgs/ClosePlayerIcon'
import MinimizePlayerIcon from '../svgs/MinimizePlayerIcon'
import PauseIcon from '../svgs/PauseIcon'

const data = [
  {
    id: 2,
    url: '/musics/YourBabyPearl - Fantasy World.mp3',
    name: 'Fancy World',
    artist: null,
  },

  {
    id: 3,
    url: '/musics/YourBabyPearl - Mondial.mp3',
    name: 'Mondial',
    artist: null,
  },

  {
    id: 1,
    url: '/musics/YourBabyPearl feat. Chriss Casper - The Greatest.mp3',
    name: 'The Greatest',
    artist: 'Chriss Casper',
  },

  {
    id: 4,
    url: '/musics/YourBabyPearl - One Life.mp3',
    name: 'One life',
    artist: null,
  },
]

export default function MusicPlaylist() {
  const currentIndex = useRef(0)
  const [playList, setPlayList] = useState(data)
  const [showPlayer, setShowPlayer] = useState(false)
  const [currentMusic, setCurrentMusic] = useState(data[0])
  const [volume, setVolume] = useState(0.5)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const audioPlayer = useRef()
  const [played, setPlayed] = useState(0)
  const [muted, setMuted] = useState(false)
  const [scrollPos, setScrollPos] = useState(null)

  const player = useRef()

  useEffect(() => {
    function detectScrollPos() {
      setScrollPos(document.body.getBoundingClientRect().top)
    }
    window.addEventListener('scroll', detectScrollPos)
    return () => window.removeEventListener('scroll', detectScrollPos)
  }, [scrollPos])

  function handlePlay() {
    if (playing) {
      setPlaying(false)
    } else {
      setPlaying(true)
    }
  }

  function handleDuration(duration) {
    setDuration(duration)
  }

  function handleSeekChange(e) {
    audioPlayer.current.seekTo(parseFloat(e.target.value))
    setPlayed(parseFloat(e.target.value))
  }

  function handleRestart() {
    audioPlayer.current.seekTo(0)
    setPlayed(0)
  }

  function handleVolumeChange(e) {
    setVolume(parseFloat(e.target.value))
  }

  function handleMute() {
    setMuted(!muted)
  }

  function handleNext() {
    currentIndex.current = (currentIndex.current + 1) % playList.length
    setCurrentMusic(playList[currentIndex.current])
    setDuration(0)
    setPlayed(0)
    setPlaying(true)
  }

  function handlePrev() {
    currentIndex.current =
      (currentIndex.current - 1 + playList.length) % playList.length
    setCurrentMusic(playList[currentIndex.current])
    setDuration(0)
    setPlayed(0)
    setPlaying(true)
  }

  function handleProgress(state) {
    setPlayed(state.played)
  }

  function handleClosePlayer() {
    setShowPlayer(false)
    setPlaying(false)
    setPlayed(0)
    audioPlayer.current.seekTo(0)
  }

  function handleMinimizePlayer() {
    setShowPlayer(false)
  }

  function format(seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }

  function handleShuffle() {
    let shuffledPlaylist = shuffleArray(playList)
    setPlayList(shuffledPlaylist)
    setCurrentMusic(shuffledPlaylist[0])
    setPlayed(0)
    audioPlayer.current.seekTo(0)
  }

  function pad(string) {
    return ('0' + string).slice(-2)
  }

  function shuffleArray(array) {
    let result = [...array]
    for (let i = result.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      let temp = result[i]
      result[i] = result[j]
      result[j] = temp
    }
    return result
  }

  return (
    <>
      <ReactPlayer
        url={currentMusic.url}
        ref={audioPlayer}
        playing={playing}
        style={{
          display: 'none',
        }}
        onDuration={handleDuration}
        volume={volume}
        muted={muted}
        onProgress={handleProgress}
        onEnded={handleNext}
      />

      {showPlayer ? (
        <div
          className="fixed left-0 right-0 bottom-0 z-[90] p-4 text-white xl:px-14"
          style={{
            background: 'rgba(193, 0, 132, 0.5)',
            border: '1px solid rgba(63, 0, 43, 0.13)',
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
          }}
        >
          <div className="max-w-[1400px] mx-auto flex flex-col md:flex-row items-center justify-between gap-4 relative">
            <img
              src="/img/music-player-icon.png"
              alt="YourBabyPearl"
              className="hidden md:block"
            />

            <div className="flex flex-col text-center">
              <div className="text-lg md:block">YourBabyPearl</div>
              {currentMusic.artist ? (
                <div className="text-sm w-max md:block">
                  feat. {currentMusic.artist}
                </div>
              ) : null}
              <div className="text-xl font-bold">{currentMusic.name} </div>
            </div>

            <div className="flex flex-col items-center space-y-4 w-full">
              <div className="flex items-center space-x-6">
                <button className="" onClick={handleShuffle}>
                  <ShuffleIcon />
                </button>
                <button className="" onClick={handlePrev}>
                  <PrevIcon />
                </button>
                {playing ? (
                  <button
                    className="flex items-center"
                    onClick={() => handlePlay()}
                  >
                    <>
                      <PauseIcon />
                    </>
                  </button>
                ) : (
                  <button
                    className="appearance-none"
                    onClick={() => handlePlay()}
                  >
                    <MusicPlayIcon />
                  </button>
                )}
                <button className="" onClick={handleNext}>
                  <NextIcon />
                </button>
                <button className="" onClick={handleRestart}>
                  <ReplayIcon />
                </button>
              </div>

              <div className="flex items-center justify-center space-x-4 w-full">
                <div className="w-[5ch]">{format(duration * played)}</div>

                <input
                  type="range"
                  min={0}
                  max={0.999999}
                  value={played}
                  step="any"
                  className="w-full xl:w-[600px] h-[5px]"
                  onChange={handleSeekChange}
                />

                <div className="w-[5ch]">{format(duration)}</div>
              </div>
            </div>

            <div className="space-x-4 max-w-[300px] hidden lg:flex lg:items-center">
              <button onClick={handleMute} className="w-max h-[45px]">
                {muted ? <MuteIcon /> : <VolumeIcon />}
              </button>

              <input
                type="range"
                min={0}
                max={1}
                value={volume}
                step="any"
                className="w-full min-w-[120px] 2xl:min-w-[200px] h-[5px]"
                onChange={handleVolumeChange}
              />
            </div>
          </div>

          <div className="absolute top-[10px] right-[10px] flex items-center space-x-2">
            <button className="" onClick={handleMinimizePlayer}>
              <MinimizePlayerIcon />
            </button>
            <button className="" onClick={handleClosePlayer}>
              <ClosePlayerIcon />
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`fixed left-0 ${
            scrollPos <= -17500 ? 'bottom-[328px]' : 'bottom-0'
          } z-[90]`}
          ref={player}
        >
          <div className="flex flex-col items-center justify-center gap-2 max-w-max mx-auto mx-0 relative text-white pt-8">
            <div
              className="flex gap-4 items-center pt-[8px] sm:pt-[20px] pr-[85px] sm:pr-[120px] pb-[8px] pl-[10px] sm:pl-[20px] bg-[#44092d] rounded-tr-[7px] relative"
              style={{
                background: 'rgba(193, 0, 132, 0.1)',
                backdropFilter: 'blur(1px)',
                WebkitBackdropFilter: 'blur(1px)',
              }}
            >
              <div className="max-w-[30px] sm:max-w-[50px] leading-[1.1rem] sm:leading-5 text-sm sm:text-lg">
                <div>Listen to</div>
                <div className="font-bold">
                  Your
                  <br />
                  Baby
                  <br />
                  Pearl
                </div>
              </div>

              <div className="absolute right-0 bottom-0">
                <img
                  src="/img/icecream-girl.png"
                  alt="Girl with an ice-cream"
                  className="max-h-[130px] sm:max-h-max"
                />
              </div>
            </div>

            <button
              onClick={() => {
                setShowPlayer(true)
                setPlaying(true)
              }}
              className="cursor-pointer bg-[#5b103a] w-full p-2 sm:p-4 flex items-center justify-center mt-[-8px] rounded-br-lg hover:bg-[#821853] transition duration-200 ease-in-out"
              style={{
                background: 'rgba(193, 0, 132, 0.2)',
                backdropFilter: 'blur(1px)',
                WebkitBackdropFilter: 'blur(1px)',
              }}
            >
              {playing ? (
                <div className="flex items-center">
                  <PauseIcon />
                </div>
              ) : (
                <PlayIcon />
              )}
            </button>
          </div>
        </div>
      )}
    </>
  )
}
