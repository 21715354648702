const data = [
  {
    id: 1,
    text: 'We want performers like YourBabyPearl to have the opportunity to use all modern and innovative means of earning fair income',
  },

  {
    id: 2,
    text: 'We encourage performers to directly express their views on the brand that they represent, to be able to improve the brand in line with their vision',
  },

  {
    id: 3,
    text: 'We want to build a safe environment for the performers to be able to express themselves',
  },

  {
    id: 4,
    text: 'We offer performers control over the persona they create in terms of style, actions and looks',
  },
];

export default function MissionStatement({ queryData }) {
  let statements = [];
  if (queryData) {
    statements = JSON.parse(queryData?.mission_items);
  }

  return (
    <section className="relative">
      <h1 className="text-title text-secondary text-center lg:absolute lg:left-0 lg:right-0 lg:top-[125px]">
        Mission statement
      </h1>

      <img
        src="/img/MissionStatementBg-mobile-3.png"
        alt=""
        className="w-full lg:hidden mt-[-20%] md:mt-[-20%]"
      />

      <img
        src="/img/MissionStatementBg-desktop-2.png"
        alt=""
        className="w-full hidden lg:block"
      />

      <div
        className="bg-cover bg-no-repeat mission-background"
        // style={{
        //   backgroundImage: "url('/img/MissionStatementBg-desktop.png')",
        // }}
      >
        <div className="px-4 3xl:px-0 flex flex-col space-y-20 2xl:section-wrapper">
          <div className="flex justify-center lg:justify-end lg:pr-[80px] xl:pr-[160px]">
            <div className="p-4"></div>

            <div className="flex flex-col gap-4 relative mt-[-100%] md:mt-[-100%] lg:mt-[-70%] 2xl:mt-[-75%] 3xl:mt-[-65%] 3xl:right-[10%] 5xl:right-[15%] 5xl:mt-[-60%]">
              {queryData
                ? statements.map((item, index) => (
                    <div
                      className="border-2 border-[#591B3E] border-b-white rounded-t-lg p-4 xl:p-6 text-secondary max-w-[450px] relative z-20 after:content-[''] after:h-[85%] after:w-[2px] after:bg-[#731D4B] after:absolute after:top-[68%] after:hidden after:left-[-24px] last:after:hidden"
                      style={{
                        background: 'hsla(312, 45%, 14%, .7)',
                      }}
                      key={index}
                    >
                      <div className="relative">
                        <div className="relative">
                          <div className="xl:text-[1.2rem] leading-[22px]">
                            {item.value}
                          </div>
                          <div className="sm:block absolute left-[-60px] top-[40%] p-[4px] border-2 border-[#470F2F] rounded-full">
                            <div className="p-[7px] bg-[#D33A88] rounded-full"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : data.map(item => (
                    <div
                      className="border-2 border-[#591B3E] border-b-white rounded-t-lg p-4 xl:p-6 text-secondary max-w-[450px] relative z-20 after:content-[''] after:h-[85%] after:w-[2px] after:bg-[#731D4B] after:absolute after:top-[68%] after:hidden after:left-[-24px] last:after:hidden"
                      style={{
                        background: 'hsla(312, 45%, 14%, .7)',
                      }}
                      key={item.id}
                    >
                      <div className="relative">
                        <div className="relative">
                          <div className="xl:text-[1.2rem] leading-[22px]">
                            {item.text}
                          </div>
                          <div className="sm:block absolute left-[-60px] top-[40%] p-[4px] border-2 border-[#470F2F] rounded-full">
                            <div className="p-[7px] bg-[#D33A88] rounded-full"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
