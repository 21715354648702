export default function PlayButton() {
  return (
    <svg
      width={116}
      height={122}
      viewBox="0 0 116 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="p-8 sm:p-0"
    >
      <path
        d="M58.2999 120.4C89.2831 120.4 114.4 93.7613 114.4 60.9004C114.4 28.0394 89.2831 1.40039 58.2999 1.40039C27.3168 1.40039 2.19995 28.0394 2.19995 60.9004C2.19995 93.7613 27.3168 120.4 58.2999 120.4Z"
        stroke="white"
        strokeWidth="2.7274"
        strokeMiterlimit="10"
      />
      <path
        d="M39.7 60.9003V32.3003L63.0999 46.6002L86.4 60.9003L63.0999 75.2002L39.7 89.5002V60.9003Z"
        fill="white"
      />
    </svg>
  )
}
