export default function VolumeIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 57 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 5L10.9633 15.602H3V29.2451H10.7763L24 40V5Z"
        fill="white"
        stroke="white"
        strokeWidth="5"
        strokeLinejoin="round"
      />
      <path
        d="M36 13.4733C37.7895 16.1548 38.7428 19.2924 38.7428 22.5C38.7428 25.7076 37.7895 28.8452 36 31.5268M42.0898 7.48354C45.3498 11.8336 47.1085 17.0959 47.1085 22.5C47.1085 27.9041 45.3498 33.1664 42.0898 37.5165M47.6649 2C52.4123 7.81228 55 15.0444 55 22.5C55 29.9556 52.4123 37.1877 47.6649 43"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}
