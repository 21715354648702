export default function DownloadIcon() {
  return (
    <svg
      width="32"
      height="26"
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 13V19C2 21.7614 4.23858 24 7 24H25C27.7614 24 30 21.7614 30 19V13"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M15.2929 14.7071C15.6834 15.0976 16.3166 15.0976 16.7071 14.7071L23.0711 8.34315C23.4616 7.95262 23.4616 7.31946 23.0711 6.92893C22.6805 6.53841 22.0474 6.53841 21.6569 6.92893L16 12.5858L10.3431 6.92893C9.95262 6.53841 9.31946 6.53841 8.92893 6.92893C8.53841 7.31946 8.53841 7.95262 8.92893 8.34315L15.2929 14.7071ZM15 0V9.69231H17V0H15ZM15 9.69231V14H17V9.69231H15Z"
        fill="white"
      />
    </svg>
  )
}
