import PdfIcon from '../svgs/PdfIcon';
import BtnJoinDiscord from './Buttons/BtnJoinDIscord';

export default function LitepaperBanner({ queryData = null }) {
  return (
    <section className="section-wrapper xl:max-w-[75%]">
      <div
        className="bg-cover bg-no-repeat flex flex-wrap gap-8 items-center justify-center md:justify-between px-4 md:px-14 py-4 rounded-lg border-2 border-[#6F124C] xl:max-w-[1400px] mx-auto"
        style={{
          background: '#2a0a1c',
        }}
      >
        <div className="flex items-center justify-between gap-4 lg:gap-8 3xl:gap-20 flex-1 max-w-max sm:mx-auto">
          <PdfIcon />

          <div className="text-xl lg:text-2xl 2xl:text-3xl text-white">
            <div>Find out more about</div>
            <div>
              <span className="text-[#d32c81] font-bold">YourBabyPearl</span>
              <span> NFT</span>
            </div>
          </div>
        </div>

        <div className="flex-1">
          <BtnJoinDiscord queryData={queryData} litepaper />
        </div>
      </div>
    </section>
  );
}
