export default function ArrowDown() {
  return (
    <svg
      width={31}
      height={31}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.8 18.9L15.9 30l13-11.1H2.8zM15.3 0v28.6"
        stroke="#fff"
        strokeWidth={1.4173}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
