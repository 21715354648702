export default function Roadmap() {
  return (
    <section className="pt-10">
      <h1 className="text-title text-secondary text-center pb-10 sm:pb-20">
        Roadmap
      </h1>

      <div
        className="bg-cover bg-center md:hidden w-full select-none"
        style={{
          backgroundImage: "url('/img/RoadmapMobileBg.png')",
        }}
      >
        <img
          src="/img/RoadmapMobileText.svg"
          alt="Roadmap"
          className="block w-full p-4"
        />
      </div>

      <div
        className="select-none w-full hidden md:block bg-cover bg-top"
        style={{
          backgroundImage: "url('/img/RoadmapDesktopBg.png')",
        }}
      >
        <img
          src="/img/RoadmapDesktopText.svg"
          alt="Roadmap"
          className="block w-full px-20 xl:max-w-[75%] mx-auto"
        />
      </div>
    </section>
  )
}
