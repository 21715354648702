export default function PauseIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 50C39.2548 50 50 39.2548 50 26C50 12.7452 39.2548 2 26 2C12.7452 2 2 12.7452 2 26C2 39.2548 12.7452 50 26 50Z"
        stroke="white"
        strokeWidth="2.7274"
        strokeMiterlimit="10"
      />
      <path d="M21 16V36" stroke="white" strokeWidth="4" />
      <path d="M31 16V36" stroke="white" strokeWidth="4" />
    </svg>
  )
}
