import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Spinner from '../components/Spinner';
import WarningModal from '../components/WarningModal';

export default function CookiePolicy() {
  let [isOpen, setIsOpen] = useState(() => {
    try {
      return !Boolean(localStorage.getItem('isOpen'));
    } catch (err) {
      return true;
    }
  });

  function closeModal() {
    setIsOpen(false);
    try {
      localStorage.setItem('isOpen', false);
    } catch (err) {}
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  const { isLoading, error, data, isFetching } = useQuery(
    ['cookiePolicy'],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_CONTENT_URL}/api/v1/page/details/cookie-policy`
        )
        .then(res => {
          if (res.status !== 200) {
            return null;
          }

          return res.data;
        })
  );

  return (
    <>
      <WarningModal isOpen={isOpen} closeModal={closeModal} />

      <div
        className="pt-16 pb-16 bg-cover bg-no-repeat min-h-screen content"
        style={{
          backgroundImage: "url('/img/TeamsBg.png')",
          backgroundSize: 'cover',
          backgroundPositionY: '-220px',
          backgroundColor: '#000',
        }}
      >
        <section className="section-wrapper flex flex-col items-center justify-center space-y-20 pb-20 max-w-[1000px]">
          {isLoading ? (
            <Spinner />
          ) : (
            <div
              className="text-white"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>
          )}
        </section>
      </div>

      <div className="bg-[#D32C81] min-w-full">
        <Footer />
      </div>
    </>
  );
}
