export default function MinimizePlayerIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        fill="#D32C81"
        fillOpacity="0.5"
        stroke="#D32C81"
      />
      <path
        d="M14.5851 11.3982V13.2158H8.95305V11.3982H14.5851Z"
        fill="white"
      />
    </svg>
  )
}
