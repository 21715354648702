export default function AngleUp() {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 1L6 6L1 0.999999" stroke="#F1F4EF" strokeWidth="2" />
    </svg>
  )
}
