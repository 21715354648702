import { useState } from 'react';
import LockedModal from './LockedModal';

export default function NftProject({ data }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function lockedTitle() {
    return {
      __html: data
        ? data.header_locked_title
        : '<span className="text-[#D32C81] font-bold">YourBabyPearl</span> is the first of 4 scheduled NFT projects. Come with us on our journey and be part of our future projects',
    };
  }

  return (
    <section className="flex flex-col items-center justify-center space-y-12 isolate text-white relative">
      <div className="flex items-center">
        <div className="space-y-4 px-4">
          <h1
            className="text-2xl sm:text-4xl text-secondary max-w-[900px] text-center"
            dangerouslySetInnerHTML={lockedTitle()}
          ></h1>
        </div>
      </div>

      <div className="flex gap-8 flex-wrap justify-center items-center">
        <div className="flex flex-col items-center justify-center gap-4 basis-[150px] xl:basis-full max-w-[200px]">
          <img src="/img/nft-proj-1.png" alt="YourBabyPearl" />
          <img
            src="/img/YourBabyPearl.svg"
            alt="Your baby pearl"
            height={150}
            width={150}
          />
        </div>

        <button
          className="flex flex-col items-center justify-center gap-4 basis-[150px] xl:basis-full max-w-[200px] cursor-pointer"
          onClick={openModal}
        >
          <img src="/img/nft-proj-2.png" alt="Locked" />
          <div className="text-xl w-[150px] h-[50.24px] flex items-center justify-center">
            Locked
          </div>
        </button>

        <button
          className="flex flex-col items-center justify-center gap-4 basis-[150px] xl:basis-full max-w-[200px] cursor-pointer"
          onClick={openModal}
        >
          <img src="/img/nft-proj-2.png" alt="Locked" />
          <div className="text-xl w-[150px] h-[50.24px] flex items-center justify-center">
            Locked
          </div>
        </button>

        <button
          className="flex flex-col items-center justify-center gap-4 basis-[150px] xl:basis-full max-w-[200px] cursor-pointer"
          onClick={openModal}
        >
          <img src="/img/nft-proj-2.png" alt="Locked" />
          <div className="text-xl w-[150px] h-[50.24px] flex items-center justify-center">
            Locked
          </div>
        </button>
      </div>

      <LockedModal isOpen={isOpen} closeModal={closeModal} />
    </section>
  );
}
