import { Disclosure, Transition } from '@headlessui/react'
import ArrowDown from '../../svgs/ArrowDown'
import ArrowUp from '../../svgs/ArrowUp'

export default function FaqItem({ title, description }) {
  // bg-[#15060E]
  return (
    <Disclosure className="">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`flex items-center justify-between text-secondary p-6 rounded-lg gap-4 border border-[#3F1129] hover:bg-[#3F1129] ${
              open ? 'bg-[#3F1129]' : 'bg-[#15060E]'
            }`}
          >
            <span className="text-left">{title}</span>
            {open ? (
              <div>
                <ArrowUp />
              </div>
            ) : (
              <div>
                <ArrowDown />
              </div>
            )}
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="text-secondary text-left text-sm border border-[#3F1129] p-6 mt-[-25px]">
              <div
                className="max-w-[450px]"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}
