export default function ShuffleIcon() {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33332 6.66688H5.99993C6.98125 6.66688 7.85724 7.18821 8.46789 8.00153C8.90122 7.19621 9.43988 6.45088 10.0559 5.78556C8.98522 4.68824 7.56791 4.00024 5.99993 4.00024H1.33332C0.595993 4.00024 0 4.59757 0 5.33356C0 6.06955 0.595993 6.66688 1.33332 6.66688Z"
        fill="white"
      />
      <path
        d="M11.5799 10.8149C12.3145 8.60556 14.5878 6.66692 16.4451 6.66692H18.8958L17.1718 8.3909C16.6505 8.91222 16.6505 9.75488 17.1718 10.2762C17.4318 10.5362 17.7731 10.6669 18.1144 10.6669C18.4558 10.6669 18.7971 10.5362 19.0571 10.2762L23.9997 5.3336L19.0571 0.390995C18.5358 -0.130332 17.6931 -0.130332 17.1718 0.390995C16.6505 0.912322 16.6505 1.75498 17.1718 2.2763L18.8958 4.00028H16.4451C13.4132 4.00028 10.1639 6.62292 9.04922 9.97221L8.4199 11.8535C7.56524 14.4215 5.65726 16.0001 4.66661 16.0001H1.33332C0.595993 16.0001 0 16.5975 0 17.3335C0 18.0694 0.595993 18.6668 1.33332 18.6668H4.66661C7.11591 18.6668 9.81721 16.1001 10.9505 12.6948L11.5799 10.8149Z"
        fill="white"
      />
      <path
        d="M17.1719 12.3908C16.6506 12.9121 16.6506 13.7547 17.1719 14.2761L18.8959 16H15.7786C14.0879 16 12.6719 14.8121 12.1906 13.1894C11.8493 14.1894 11.3546 15.188 10.7373 16.1027C11.9186 17.652 13.7279 18.6667 15.7786 18.6667H18.8959L17.1719 20.3907C16.6506 20.912 16.6506 21.7546 17.1719 22.276C17.4319 22.536 17.7732 22.6666 18.1145 22.6666C18.4559 22.6666 18.7972 22.536 19.0572 22.276L23.9998 17.3334L19.0572 12.3908C18.5359 11.8694 17.6932 11.8694 17.1719 12.3908Z"
        fill="white"
      />
    </svg>
  )
}
