import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import PlayButton from '../svgs/PlayButton';

export default function Video({ data }) {
  const [playing, setPlaying] = useState(false);
  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  function videoSubtitle() {
    return {
      __html: data
        ? data.header_video_subtitle
        : `Directly from our PornStar, <span className="text-primary font-bold">YourBabyPearl</span>`,
    };
  }

  return (
    <section className="section-wrapper flex flex-col items-center justify-center space-y-12 isolate">
      <header className="flex flex-col items-center space-y-3">
        <h1 className="text-title text-secondary max-w-[500px] text-center">
          {data ? data.header_video_title : "Find out what it's all about"}
        </h1>

        <p
          className="text-subtitle text-secondary text-center"
          dangerouslySetInnerHTML={videoSubtitle()}
        ></p>
      </header>

      <div className="border-4 border-blue-900 shadow-lg shadow-fuchsia-700 w-full max-w-[640px]">
        <ReactPlayer
          url="/intro-video.mp4"
          playing={playing}
          controls={true}
          onClickPreview={() => {
            setPlaying(true);
          }}
          playIcon={width > 1000 ? <PlayButton /> : null}
          light={width > 1000 ? '/img/video-preview.png' : false}
          width="100%"
          height={width > 1000 ? '355.5px' : 'auto'}
          style={{
            maxWidth: '640px',
          }}
        />
      </div>
    </section>
  );
}
