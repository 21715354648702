import { InView } from 'react-intersection-observer';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Faq from '../components/Faq/Faq';
import Footer from '../components/Footer';
import { Header } from '../components/Header';
import InvestmentPlan from '../components/InvestmentPlan';
import JoinDiscordBanner from '../components/JoinDiscordBanner';
import LitepaperBanner from '../components/LitepaperBanner';
import MissionStatement from '../components/MissionStatement';
import MusicPlaylist from '../components/MusicPlaylist';
import NftProject from '../components/NftProject';
import Partners from '../components/Partners';
import Roadmap from '../components/Roadmap';
import Team from '../components/Team';
import Tokenomics from '../components/Tokenomics/Tokenomics';
import Video from '../components/Video';
import WarningModal from '../components/WarningModal';
import WhatMakesUsDifferent from '../components/WhatMakesUsDifferent';
import AngleUp from '../svgs/AngleUp';
import RadioActive from '../svgs/RadioActive';
import RadioInactive from '../svgs/RadioInactive';

function Home() {
  const [activeNavItem, setActiveNavItem] = useState('');
  const [hoveredItem, setHoveredItem] = useState(null);
  let [isOpen, setIsOpen] = useState(() => {
    try {
      return !Boolean(localStorage.getItem('isOpen'));
    } catch (err) {
      return true;
    }
  });
  const navItems = [
    {
      title: 'Header',
      id: 'header',
    },
    {
      title: 'NFT utilities and benefits',
      id: 'tokenomics',
    },
    {
      title: 'What makes us different',
      id: 'whatmakesusdifferent',
    },
    {
      title: 'Mission statement',
      id: 'missionstatement',
    },
    {
      title: 'Investment plan',
      id: 'investmentplan',
    },
    {
      title: 'Roadmap',
      id: 'roadmap',
    },
    {
      title: 'Team',
      id: 'team',
    },
    {
      title: 'Faq',
      id: 'faq',
    },
  ];
  const scrollToSection = (e, id) => {
    e.preventDefault();
    document
      .getElementById(id)
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  function closeModal() {
    setIsOpen(false);
    try {
      localStorage.setItem('isOpen', false);
    } catch (err) {}
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const { isLoading, error, data, isFetching } = useQuery(['landingData'], () =>
    axios
      .get(`${process.env.REACT_APP_CONTENT_URL}/api/v1/landing/index`)
      .then(res => {
        if (res.status === 200) {
          return res.data;
        } else {
          return null;
        }
      })
  );

  return (
    <div className="bg-black space-y-20">
      <WarningModal isOpen={isOpen} closeModal={closeModal} />

      <InView
        onChange={(inView, entry) => inView && setActiveNavItem('header')}
      >
        {({ inView, ref, entry }) => (
          <div className="relative z-50" id={'header'} ref={ref}>
            <span
              className="fixed top-0 bottom-0 right-0 h-screen z-50 hidden md:block"
              style={{
                width: '50px',
                backgroundColor: 'rgba(255,255,255, .08)',
              }}
            ></span>
            <ul
              className={`hidden z-50 md:flex md:flex-col md:items-end md:space-y-4 ${
                (!hoveredItem && activeNavItem === 'faq') ||
                (!hoveredItem && activeNavItem === 'tokenomics')
                  ? 'w-[30px]'
                  : 'w-max'
              }`}
              style={{
                color: '#fff',
                position: 'fixed',
                right: '15px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {/* ARROW UP */}
              <li
                className={`${
                  activeNavItem === 'header'
                    ? 'opacity-40 pointer-events-none'
                    : ''
                } nav-link my-3 ml-auto flex justify-end prevButton`}
                key={`nav-item-prev`}
              >
                <a
                  href="#"
                  className="flex p-2"
                  onClick={e => {
                    if (activeNavItem === 'header') return;
                    scrollToSection(
                      e,
                      navItems[
                        navItems.map(el => el.id).indexOf(activeNavItem) - 1
                      ].id
                    );
                  }}
                >
                  <div className="mr-[-2px] rotate-180">
                    <AngleUp />
                  </div>
                </a>
              </li>

              {navItems.map(el => {
                const isActive = activeNavItem === el.id;
                const isHovered = hoveredItem === el.id;
                return (
                  <li
                    className={`${
                      isActive ? 'activeElement' : 'inactiveElement'
                    } nav-link my-3 h-[35px] w-max`}
                    key={`nav-item-${el.id}`}
                  >
                    <a
                      className="flex justify-end"
                      href={`#${el.id}`}
                      onClick={e => {
                        setActiveNavItem(el.id);
                        scrollToSection(e, el.id);
                      }}
                    >
                      <span
                        style={{
                          border: '1.8px solid lightgray',
                          borderTopLeftRadius: '999999px',
                          borderBottomLeftRadius: '999999px',
                          borderRight: 0,
                        }}
                        className={`nav-link-title pl-3 pr-1 mr-[13px] py-[2px] mt-[-5px] ${
                          isHovered ? 'block' : 'hidden'
                        }`}
                      >
                        {el.title}
                      </span>
                      <div
                        className="nav-link-button block"
                        onMouseEnter={() => {
                          setHoveredItem(el.id);
                        }}
                        onMouseLeave={() => {
                          setHoveredItem('');
                        }}
                      >
                        {isActive ? <RadioActive /> : <RadioInactive />}
                      </div>
                    </a>
                  </li>
                );
              })}

              {/* ARROW DOWN */}
              <li
                className={`${
                  activeNavItem === 'faq'
                    ? 'opacity-40 pointer-events-none'
                    : ''
                } nav-link my-3 ml-auto flex justify-end prevButton`}
                key={`nav-item-next`}
              >
                <a
                  href="#"
                  className="flex p-2"
                  onClick={e => {
                    if (activeNavItem === 'faq') return;
                    scrollToSection(
                      e,
                      navItems[
                        navItems.map(el => el.id).indexOf(activeNavItem) + 1
                      ].id
                    );
                  }}
                >
                  <div className="mr-[-2px]">
                    <AngleUp />
                  </div>
                </a>
              </li>
            </ul>
            <Header data={data} />
          </div>
        )}
      </InView>

      <MusicPlaylist />

      <NftProject data={data} />

      <div
        className="bg-cover space-y-20"
        style={{
          backgroundImage: "url('/img/VideoBg.png')",
          backgroundPositionY: '15%',
        }}
      >
        <Video data={data} />
        <InView
          threshold={0.2}
          onChange={(inView, entry) => inView && setActiveNavItem('tokenomics')}
        >
          {({ inView, ref, entry }) => (
            <div id={'tokenomics'} ref={ref}>
              <Tokenomics data={data} />
            </div>
          )}
        </InView>
      </div>

      <LitepaperBanner queryData={data} />

      <InView
        threshold={0.2}
        onChange={(inView, entry) =>
          inView && setActiveNavItem('whatmakesusdifferent')
        }
      >
        {({ inView, ref, entry }) => (
          <div id={'whatmakesusdifferent'} ref={ref}>
            <WhatMakesUsDifferent />
          </div>
        )}
      </InView>

      <JoinDiscordBanner />

      <InView
        threshold={0.2}
        onChange={(inView, entry) =>
          inView && setActiveNavItem('missionstatement')
        }
      >
        {({ inView, ref, entry }) => (
          <div id={'missionstatement'} ref={ref}>
            <MissionStatement queryData={data} />
          </div>
        )}
      </InView>

      <InView
        threshold={0.2}
        onChange={(inView, entry) =>
          inView && setActiveNavItem('investmentplan')
        }
      >
        {({ inView, ref, entry }) => (
          <div id={'investmentplan'} ref={ref}>
            <InvestmentPlan />
          </div>
        )}
      </InView>

      <InView
        threshold={0.2}
        onChange={(inView, entry) => inView && setActiveNavItem('roadmap')}
      >
        {({ inView, ref, entry }) => (
          <div id={'roadmap'} ref={ref}>
            <Roadmap />
          </div>
        )}
      </InView>

      <JoinDiscordBanner />

      <div
        className="bg-cover bg-no-repeat"
        style={{
          backgroundImage: "url('/img/TeamsBg.png')",
          backgroundSize: '100% 90%',
          backgroundPositionY: '-125px',
        }}
      >
        <InView
          threshold={0.2}
          onChange={(inView, entry) => inView && setActiveNavItem('team')}
        >
          {({ inView, ref, entry }) => (
            <div id={'team'} ref={ref}>
              <Team />
            </div>
          )}
        </InView>

        <Partners />

        <InView
          threshold={0.9}
          onChange={(inView, entry) => inView && setActiveNavItem('faq')}
        >
          {({ inView, ref, entry }) => (
            <div id={'faq'} ref={ref}>
              <Faq queryData={data} />
            </div>
          )}
        </InView>
      </div>

      {/* <Videos /> */}

      <footer className="bg-primary-dark">
        <Footer />
      </footer>
    </div>
  );
}

export default Home;
