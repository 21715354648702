export default function ArrowLeft({ stroke = '#4D4D4D' }) {
  return (
    <svg
      width={22}
      height={40}
      viewBox="0 0 22 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9001 0.900391L1.1001 20.5996L19.1001 38.5996"
        stroke={stroke}
        strokeWidth="1.4173"
        strokeMiterlimit={10}
      />
    </svg>
  )
}
