import FaqItem from './FaqItem';

const data = [
  {
    id: 1,
    title: 'Where can I buy a YourBabyPearl NFT?',
    description:
      '<p>You can mint your YourBabyPearl NFT directly from the <a target="_blank" href="https://mint.yourbabypearl.com/"><span style="color:#d32c81;">YBP Minting</span></a> website! The secondary market sales will be held on <a target="_blank" style="color:blue;" href="https://opensea.io/">https://opensea.io/</a><p>',
  },

  {
    id: 2,
    title: 'What blockchain are YourBabyPearl NFTs deployed on?',
    description:
      '<p>YourBabyPearl NFTs is deployed on the Ethereum Blockchain using an ERC-721A Contract - <a href="https://etherscan.io/address/0x04f469a2b27efecd04c451ca9dc1569b4732e400" target="_blank" style="color:blue; word-wrap: break-all;">Check it on Etherscan.</p>',
  },

  {
    id: 3,
    title: 'What wallet can I use to mint YourBabyPearl NFTs?',
    description:
      'In order to mint YourBabyPearl NFTs you have to use the Metamask wallet',
  },

  {
    id: 4,
    title: 'How many YourBabyPearl NFTs will be available?',
    description:
      'The total collection has 10,000 NFTs that you can mint on our website or buy from Opensea',
  },

  {
    id: 5,
    title: 'How many types of YourBabyPearl NFTs are there?',
    description:
      'There are 3 types of NFTs, each with their own perks and benefits: PearlMaster, GoldenPearl and SugarPearl. You can see exactly what benefits you have with each of them on our landing page',
  },

  {
    id: 6,
    title: 'Where can I view my NFTs?',
    description:
      'You can view the NFTs that you hold in your wallet, on Opensea and on our content platform in the passive income claim page',
  },

  {
    id: 7,
    title: 'Where can I access the utilities?',
    description:
      'You can access digital perks and benefits, like media content, live streaming, discounts and passive income, on our website www.yourbabypearl.com. Join our Discord server to find out more about the real life benefits, like our annual event.',
  },

  {
    id: 8,
    title: 'How do I earn the passive income?',
    description: `We are redistributing 40% of  royalties to our community i.e. 2% of the value of all secondary market transactions. All community royalties will be allocated to a community pool, and NFT holders will be able to claim their income in ETH quarterly directly on our website on the claim page.  We will announce each quarter end in due time so that you are prepared to mint/buy the NFT!

    NFT holders can claim the passive income at any time after quarter end, and they can claim for multiple quarters. If you sell the NFT, you cannot claim the passive income anymore. Only NFT holders can claim passive income on our website. All passive income which is not claimed will be redistributed to the rest of the holders 3 years.`,
  },

  {
    id: 9,
    title: 'How can I get the discount to streaming platforms?',
    description:
      'YourBabyPearl gives you discounts to her live streams on StripChat, Chaturbate and other streaming platforms where YourBabyPearl has an account and allow discounts to members. In order to benefit from the discount, you need to fill in the form on the website with your username so that our team knows when to apply the discount. Discounts are valid only 7 days from the moment of request. After that, you have to request the discount again. There is no limit to how many discounts you can get.',
  },

  {
    id: 10,
    title: 'How can I get on the whitelist to buy YourBabyPearl NFTs?',
    description:
      'Join our Discord server and follow us on social media to find out how you can get whitelisted!',
  },
];

export default function Faq({ queryData }) {
  let items = [];
  if (queryData) {
    items = JSON.parse(queryData?.faq_items);
  }

  return (
    <section className="section-wrapper flex flex-col items-center justify-center space-y-16">
      <h1 className="text-secondary text-title">FAQ</h1>

      <div
        className={`flex flex-col lg:flex-row justify-center ${
          data.length > 5 ? 'gap-4' : 'max-w-[800px]'
        }`}
      >
        {queryData ? (
          <>
            <div className="flex flex-col flex-1 gap-4">
              {items.slice(0, 5).map((item, index) => (
                <FaqItem
                  key={index}
                  title={item.question}
                  description={item.answer}
                />
              ))}
            </div>

            <div
              className={`flex flex-col gap-4 flex-${
                items.slice(5).length ? '1' : '0'
              }`}
            >
              {items.slice(5).map((item, index) => (
                <FaqItem
                  key={index}
                  title={item.question}
                  description={item.answer}
                />
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col flex-1 gap-4">
              {data.slice(0, 5).map(item => (
                <FaqItem
                  key={item.id}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>

            <div
              className={`flex flex-col gap-4 flex-${
                data.slice(5).length ? '1' : '0'
              }`}
            >
              {data.slice(5).map(item => (
                <FaqItem
                  key={item.id}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
}
