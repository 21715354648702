const data = [
  {
    id: 1,
    name: 'YourBabyPearl',
    title: 'The Star',
    img: '/img/team-1.png',
    desc: 'Started as a stream performer at 19 y. o. and ended up being “top 10 stream girl” by 20 y.o., YourBabyPearl is that happy, super sexual and smart girl that fires up the stages and streams with her wet and hit shows. 21 y.o. now and with a very bright future ahead… Oh, yes, she is a PStar already!',
  },

  {
    id: 2,
    name: 'Chriss Casper',
    title: 'CEO',
    img: '/img/team-2.png',
    desc: 'Content creator with 13 years experience in the adult stream industry and media production, singer, songwriter and long time marketing strategist, has raised brands to new heights and managed teams to amazing performances while having fun and enjoying the ride',
  },

  {
    id: 3,
    name: 'Bayar',
    title: 'CFO',
    img: '/img/team-3.png',
    desc: 'Uses his extensive management, finance and consulting expertise to develop blockchain strategies for existing businesses',
  },

  {
    id: 4,
    name: 'Madalina D',
    title: 'NFT Art Graphic Design',
    img: '/img/team-4.png',
    desc: 'Interested in art since childhood, she turned a hobby into her profession, being a successful tattoo artist for more than 3 years, and digital artist working on multiple NFT projects, transforming her creativity and imagination into an invaluable asset to the team',
  },

  {
    id: 5,
    name: 'Serban',
    title: 'Web Design',
    img: '/img/team-5.png',
    desc: 'An optimistic ambivert, always focused on finding the best there is in people and reveal it to them',
  },

  {
    id: 6,
    name: 'Madalina B',
    title: 'Marketing Manager',
    img: '/img/team-7.png',
    desc: 'With 9 years of experience in the Marketing and Business Development area, she likes to challenge her ambition, creativity and imagination',
  },

  {
    id: 7,
    name: 'Mitza',
    title: 'Music Management',
    img: '/img/team-8.png',
    desc: 'Management and strategy of our music department, he thrives on content creator growth and development. GRNDCTRL Records label manager.',
  },

  {
    id: 8,
    name: 'Nova',
    title: 'Music Producer',
    img: '/img/team-6.png',
    desc: "The talent behind YBP's music productions, he's an explorer of sound design and alternative music. Founder of GRNDCTRL Records.",
  },

  {
    id: 9,
    name: 'Silviu',
    title: 'Songwriter and producer',
    img: '/img/team-9.png',
    desc: 'Executive producer and provocative songwriter with over 10 years experience in creating music stars, ensures cohesiveness of the musical project. Founder of GRNDCTRL Records.',
  },
]

export default function Team() {
  return (
    <section className="section-wrapper flex flex-col items-center justify-center space-y-20 pb-20 max-w-[1000px]">
      <header>
        <h1 className="text-title text-secondary">Team</h1>
      </header>

      <div className="flex flex-wrap items-center justify-center gap-10 sm:gap-20">
        {data.map(item => (
          <div
            key={item.id}
            className="flex flex-col gap-4 items-center jusity-center text-center self-baseline"
          >
            <div className="flex items-center justify-center border-2 border-white rounded-full p-2 h-[150px] w-[150px] sm:h-[210px] sm:w-[210px]">
              <div className="p-2 rounded-full mx-auto">
                <img
                  src={item.img}
                  alt="Team member image"
                  className="mx-auto"
                />
              </div>
            </div>

            <div className="text-secondary">
              <div className="text-xl font-medium">{item.name}</div>
              <div className="text-md mb-2">{item.title}</div>
              <p className="max-w-[230px] text-sm">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
