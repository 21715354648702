import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <div className="section-wrapper flex flex-col xl:flex-row items-center justify-center xl:justify-around flex-wrap py-8 gap-10 md:max-w-[1200px] xl:pl-8 hidden xl:flex">
        <div className="text-center xl:text-left text-lg text-white max-w-max flex-1">
          <Link to="/">
            <img src="/img/footer-logo.png" alt="" />
          </Link>
        </div>

        <div className="text-center text-lg flex-1 text-white max-w-max">
          <ul className="list-none">
            <li>
              <Link to={'/cookie-policy'}>Cookie Policy</Link>
              {/* <CookiePolicy /> */}
            </li>
            <li>
              <Link to={'/terms-conditions'}>Terms & Conditions</Link>
              {/* <TermsOfUse /> */}
            </li>
            <li>
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
              {/* <PrivacyPolicy /> */}
            </li>
          </ul>
        </div>

        <div className="flex flex-col-reverse xl:flex-row gap-10">
          <div className="flex flex-col space-y-4 items-center mx-auto text-center max-w-max flex-1 mr-0">
            <div className="flex space-x-4 items-center justify-end w-full">
              <a
                href="mailto:loveme@yourbabypearl.com"
                className="cursor-pointer"
                target={'_blank'}
              >
                <img src="/img/email.svg" alt="Email" />
              </a>
              <a
                href="https://wa.me/40759636324"
                className="cursor-pointer"
                target={'_blank'}
              >
                <img src="/img/whatsapp.svg" alt="Whatsapp" />
              </a>
              <a
                className="cursor-pointer"
                href="https://twitter.com/YBP_NFT"
                target={'_blank'}
              >
                <img src="/img/twitter.svg" alt="Twitter" />
              </a>
              <a
                className="cursor-pointer"
                href="https://www.instagram.com/_yourbabypearl_nft/"
                target={'_blank'}
              >
                <img src="/img/instagram.svg" alt="Instagram" />
              </a>
              <a className="cursor-pointer">
                <img src="/img/spotify.svg" alt="Spotify" />
              </a>
            </div>

            <div className="text-white flex-1 text-md xl:text-md text-right">
              All rights reserved by YourBabyPearl Ltd. <br /> &copy; 2022
              YourBabyPearl Ltd.
            </div>
          </div>
        </div>
      </div>

      <div className="section-wrapper flex flex-col xl:flex-row items-center justify-center xl:justify-around flex-wrap py-8 gap-10 md:max-w-[1200px] xl:pl-8 xl:hidden">
        <div className="text-center xl:text-left text-lg text-white max-w-max">
          <ul className="list-none">
            <li>
              <Link to={'/cookie-policy'}>Cookie Policy</Link>
              {/* <CookiePolicy /> */}
            </li>
            <li>
              <Link to={'/terms-conditions'}>Terms & Conditions</Link>
              {/* <TermsOfUse /> */}
            </li>
            <li>
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
              {/* <PrivacyPolicy /> */}
            </li>
          </ul>
        </div>

        <div className="hidden text-white xl:block xl:flex-1 text-md xl:text-xl text-center">
          All rights reserved by YourBabyPearl Ltd. <br /> &copy; 2022
          YourBabyPearl Ltd.
        </div>

        <div className="hidden xl:flex flex-col space-y-4 items-center mx-auto text-center max-w-max">
          <div className="flex space-x-4 items-center justify-center">
            <a
              href="mailto:loveme@yourbabypearl.com"
              className="cursor-pointer"
              target={'_blank'}
            >
              <img src="/img/email.svg" alt="Email" />
            </a>
            <a
              className="cursor-pointer"
              href="https://wa.me/40759636324"
              target={'_blank'}
            >
              <img src="/img/whatsapp.svg" alt="Whatsapp" />
            </a>
            <a
              className="cursor-pointer"
              href="https://twitter.com/YBP_NFT"
              target={'_blank'}
            >
              <img src="/img/twitter.svg" alt="Twitter" />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.instagram.com/_yourbabypearl_nft/"
              target={'_blank'}
            >
              <img src="/img/instagram.svg" alt="Instagram" />
            </a>
            <a className="cursor-pointer">
              <img src="/img/spotify.svg" alt="Spotify" />
            </a>
          </div>
        </div>

        <div className="flex flex-col-reverse xl:flex-row gap-10 xl:hidden">
          <div className="text-white flex-1 text-md xl:text-xl text-center">
            All rights reserved by YourBabyPearl Ltd. <br /> &copy; 2022
            YourBabyPearl Ltd.
          </div>

          <div className="flex flex-col space-y-4 items-center mx-auto text-center w-max flex-1">
            <div className="flex space-x-4 items-center justify-center">
              <a
                href="mailto:loveme@yourbabypearl.com"
                className="cursor-pointer"
                target={'_blank'}
              >
                <img src="/img/email.svg" alt="Email" />
              </a>
              <a
                href="https://wa.me/40759636324"
                className="cursor-pointer"
                target={'_blank'}
              >
                <img src="/img/whatsapp.svg" alt="Whatsapp" />
              </a>
              <a
                className="cursor-pointer"
                href="https://twitter.com/YBP_NFT"
                target={'_blank'}
              >
                <img src="/img/twitter.svg" alt="Twitter" />
              </a>
              <a
                className="cursor-pointer"
                href="https://www.instagram.com/_yourbabypearl_nft/"
                target={'_blank'}
              >
                <img src="/img/instagram.svg" alt="Instagram" />
              </a>
              <a className="cursor-pointer">
                <img src="/img/spotify.svg" alt="Spotify" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
