export const Header = ({ data }) => (
  <header
    className="lg:min-h-screen relative"
    style={{
      backgroundImage: `url('/img/HeaderBg.png'), url('/img/Flare_Bg_Hero.png'), url('/img/Flare_Bg_Hero.png')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: `bottom, bottom, bottom`,
      backgroundSize: 'cover',
    }}
  >
    <img
      className="absolute inset-0 z-0 w-full h-screen opacity-50"
      src="/img/Flare_Bg_Hero.png"
      alt="YBP Flare"
    />

    <div className="container mx-auto flex flex-wrap xl:flex-nowrap items-center justify-center relative z-10">
      <div className="flex flex-col flex-wrap scroll-px-8 sm:w-3/6 items-center justify-between pt-8 md:pt-16 w-5/6 mx-auto space-y-4 sm:space-y-8">
        {/* <img className="max-w-l" src="/img/HeaderIntro.svg" alt="" /> */}
        <div className="font-bold header-text whitespace-pre-wrap">
          {data
            ? `${data.header_title.split('<br/>').join('\n')}`
            : `Welcome to ${'\n'} the future of porn!`}
        </div>

        <img
          className="my-4 max-w-xl w-full"
          src="/img/YourBabyPearl.svg"
          alt=""
        />

        <p className="text-white text-center font-normal leading-[22px] lg:text-[1.2rem] 2xl:text-[1.3rem] 3xl:text-[1.4rem] 3xl:leading-6 max-w-lg">
          {data
            ? data.header_subtitle
            : 'The first premium adult NFT project that brings immediate & exclusive benefits to members, real value for investors… and more!'}
        </p>

        <div className="flex flex-col gap-4">
          <a
            className="hover:translate-y-[-5px] transition-transform duration-500"
            href="https://discord.com/invite/XvK3w9RpxE"
            target={'_blank'}
          >
            <img
              className="mx-auto w-full bg-transparent"
              src="/img/DiscordButton.svg"
              alt=""
            />
          </a>

          <a
            className="hover:translate-y-[-5px] transition-transform duration-500 mb-4"
            href="https://mint.yourbabypearl.com/"
            target={'_blank'}
          >
            <img
              className="mx-auto w-full bg-transparent"
              src="/img/MintButton.svg"
              alt=""
            />
          </a>
        </div>
      </div>

      <img
        className="mx-auto hidden xl:h-screen lg:block header-image"
        src="/img/hero-girl.png"
        alt="YourBabyPearl"
      />

      <img
        className="mx-auto w-[300px] sm:w-3/6 lg:hidden block"
        src="/img/hero-girl.png"
        alt="YourBabyPearl"
      />
    </div>
  </header>
);
