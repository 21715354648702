export default function LockIcon() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 0C17.9102 0 0 17.9102 0 40C0 62.0898 17.9102 80 40 80C62.0898 80 80 62.0898 80 40C80 17.9102 62.0898 0 40 0ZM40 76.5224C19.8286 76.5224 3.47755 60.1714 3.47755 40C3.47755 19.8286 19.8286 3.47755 40 3.47755C60.1714 3.47755 76.5224 19.8286 76.5224 40C76.5224 60.1714 60.1714 76.5224 40 76.5224Z"
        fill="white"
      />
      <path
        d="M34.9564 31.2162C34.9564 29.2162 35.4789 24.4326 40.6054 24.4326C45.4789 24.4326 46.2544 28.6938 46.2544 31.2162V32.6938H50.5156V31.2162C50.5156 24.604 46.6871 20.3428 40.6871 20.3428C34.6871 20.3428 30.8585 24.604 30.8585 31.2162V32.6938H35.1197V31.2162H34.9564ZM50.434 34.0897H30.7768C28.4258 34.0897 26.5156 35.9183 26.5156 38.2611V51.9999C26.5156 54.2611 28.4258 56.1713 30.7768 56.1713H50.4258C52.7768 56.1713 54.6871 54.3428 54.6871 51.9999V38.2611C54.6952 35.9999 52.7768 34.0897 50.434 34.0897ZM41.9932 46.1713V49.306C41.9932 50.0897 41.3809 50.6938 40.6054 50.6938C39.8217 50.6938 39.2177 50.0815 39.2177 49.306V46.1795C38.3442 45.7469 37.8299 44.7918 37.8299 43.8285C37.8299 42.3509 39.0462 41.0448 40.6136 41.0448C42.1809 41.0448 43.3973 42.2611 43.3973 43.8285C43.3891 44.7836 42.8666 45.6489 41.9932 46.1713Z"
        fill="white"
      />
    </svg>
  )
}
