import DiscordIcon from '../../svgs/DiscordIcon';
import DownloadIcon from '../../svgs/DownloadIcon';

export default function BtnJoinDiscord({
  outlined = true,
  litepaper = false,
  queryData = null,
}) {
  if (litepaper) {
    let docUrl =
      'https://nft.yourbabypearl.com/YourBabyPearl_NFT_litepaper.pdf';
    if (queryData) {
      docUrl = `${process.env.REACT_APP_CONTENT_URL}/storage/${queryData.utilities_document}`;
    }

    return (
      <div className="border-2 border-[#43112b] p-1 bg-transparent rounded-full hover:bg-[#D02E80] hover:transition-all ease-in duration-150 max-w-max mx-auto sm:ml-auto sm:mr-0">
        <a
          href={docUrl}
          target={'_blank'}
          className="flex gap-4 items-center justify-between px-6 py-2 border-2 border-[#D02E80] rounded-full"
        >
          <div>
            <DownloadIcon />
          </div>

          <div className="text-secondary text-base sm:text-lg">
            Read the Litepaper
          </div>
        </a>
      </div>
    );
  }

  return (
    <div className="border-2 border-[#381350] p-2 bg-transparent rounded-full hover:bg-[#D02E80] hover:transition-all ease-in duration-150">
      <a
        href="https://discord.com/invite/XvK3w9RpxE"
        target={'_blank'}
        className="flex gap-4 items-center justify-between px-4 md:px-10 lg:px-20 py-4 border-2 border-[#D02E80] rounded-full"
      >
        <div>
          <DiscordIcon />
        </div>

        <div className="text-secondary text-base sm:text-lg">
          Join us on Discord
        </div>
      </a>
    </div>
  );
}
