export default function ArrowUp() {
  return (
    <svg
      width={31}
      height={32}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9 12.7l-13-11.1L2.8 12.7h26.1zM16.4 31.6V3"
        stroke="#fff"
        strokeWidth={1.4173}
        strokeMiterlimit={10}
      />
    </svg>
  )
}
