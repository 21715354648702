import { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import ArrowLeft from '../../svgs/ArrowLeft';
import CardBorder from '../../svgs/CardBorder';
import Tooltip from '../Utility/Tooltip';

export default function Tokenomics({ data }) {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);
  const scrollContainer = useRef(null);
  const [observing, setObserving] = useState('master');

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    setContainerWidth(containerRef.current.getBoundingClientRect().width);
  }, []);

  const nftBoxes = (
    <div
      className="flex items-stretch max-w-screen-xl mx-auto ml-[-10px] idk-why"
      ref={containerRef}
    >
      <button
        className="h-max mt-[230px] sm:hidden"
        onClick={() => {
          scrollContainer.current.scrollLeft += 273;
        }}
      >
        <ArrowLeft stroke="#F486DD" />
      </button>

      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('master');
          }
        }}
        className="w-[295px] sm:w-full"
      >
        <div
          className={`w-[295px] sm:w-full ${
            observing !== 'master' ? 'opacity-40' : 'opacity-100'
          } sm:opacity-100 ml-[-5px] sm:ml-0`}
        >
          <div className="nft-box-image relative">
            <img
              className="w-[390px]"
              src="/img/nft-bg-pink.png"
              alt="NFT background"
            />
            <img
              className="w-[390px] nft-placeholder absolute bottom-0"
              src="/img/nft-placeholder.png"
              alt="NFT placeholder"
            />
          </div>
          <div className="box-content flex flex-col px-6">
            <p className="md:text-xl text-md text-white" ref={elementRef}>
              YourBabyPearl NFT
            </p>
            <h3 className="md:text-4xl text-4xl text-white">
              <strong>Pearl</strong>Master
            </h3>
          </div>
          <div
            className="w-full px-4 flex py-8 mx-auto"
            style={{ maxWidth: '300px' }}
          >
            <CardBorder />
          </div>
        </div>
      </InView>
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('gold');
          }
        }}
        className="w-[295px] sm:w-full"
      >
        <div
          className={`w-[295px] sm:w-full ${
            observing !== 'gold' ? 'opacity-40' : 'opacity-100'
          } sm:opacity-100 ml-[-13px] sm:ml-0`}
        >
          <div className="nft-box-image relative">
            <img
              className="w-[390px]"
              src="/img/nft-bg-blue.png"
              alt="NFT background"
            />
            <img
              className="w-[390px] nft-placeholder absolute bottom-0"
              src="/img/nft-placeholder.png"
              alt="NFT placeholder"
            />
          </div>
          <div className="box-content flex flex-col px-6">
            <p className="md:text-xl text-md text-white">YourBabyPearl NFT</p>
            <h3 className="md:text-4xl text-4xl text-white">
              <strong>Golden</strong>Pearl
            </h3>
          </div>
          <div
            className="w-full px-4 flex py-8 mx-auto"
            style={{ maxWidth: '300px' }}
          >
            <CardBorder />
          </div>
        </div>
      </InView>
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('sugar');
          }
        }}
        className="w-[295px] sm:w-full"
      >
        {({ inView, ref, entry }) => (
          <div
            className={`w-[295px] sm:w-full ${
              observing !== 'sugar' ? 'opacity-40' : 'opacity-100'
            } sm:opacity-100 ml-[-22px] sm:ml-0`}
            ref={ref}
          >
            <div className="nft-box-image relative">
              <img
                className="w-[390px]"
                src="/img/nft-bg-green.png"
                alt="NFT background"
              />
              <img
                className="w-[390px] nft-placeholder absolute bottom-0"
                src="/img/nft-placeholder.png"
                alt="NFT placeholder"
              />
            </div>
            <div className="box-content flex flex-col px-6">
              <p className="md:text-xl text-md text-white">YourBabyPearl NFT</p>
              <h3 className="md:text-4xl text-4xl text-white">
                <strong>Sugar</strong>Pearl
              </h3>
            </div>
            <div
              className="w-full px-4 flex py-8 mx-auto"
              style={{ maxWidth: '300px' }}
            >
              <CardBorder />
            </div>
          </div>
        )}
      </InView>
    </div>
  );

  const contentBoxes = (
    <div
      className="flex items-stretch max-w-screen-xl"
      style={{ width: `${containerWidth}px` }}
    >
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('master');
          }
        }}
        className="w-full flex lg:justify-center "
      >
        <div
          className={`w-full flex lg:justify-center ${
            observing !== 'master' ? 'opacity-40' : 'opacity-100'
          } sm:opacity-100`}
        >
          <ul
            //
            className="nft-box nft-textbox py-9 px-6 font-normal"
          >
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Weekly stream show </div>
              <Tooltip
                content={
                  'Get to chat with YourBabyPearl in her super wet weekly stream shows! The stream will contain meet & greet, Q&A and full porn show'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Monthly stream party </div>
              <Tooltip
                content={
                  'This monthly event will make you take all the towels from the house next to you. Yes! That hot and wet. Basically, this is the ultimate stream show! YourBabyPearl takes this very personally :) Are you ready? We know you are not.'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Access to premium media gallery</div>
              <Tooltip
                content={
                  'Access to all paid content which is published on all YourBabyPearl’s accounts on other pay-per-view websites, only with your NFT'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Access to NFT exclusive media gallery</div>
              <Tooltip
                content={
                  'Only the hottest videos ranging from amateur to super-pro productions that you’ll get to see only with NFT authentication. These productions are made exclusively for the NFT holders to see. You will never see this content anywhere else. Yes, it’s unique for the NFT holders!'
                }
              />
            </li>

            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Annual real life event</div>
              <Tooltip
                content={
                  'Live shows, private shows, stage shows, YBP concerts, merchandise, public meet & greet, Q&A… basically everything that you see on our streams and more. Get a chance to actually meet YourBabyPearl in real life, discuss collaborations, projects and the future of porn.'
                }
              />
            </li>

            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>
                Passive income (<strong>2%</strong> of all secondary market
                transactions)
              </div>
              <Tooltip
                content={
                  'Earn a share of royalties just for holding an NFT! NFT holders will receive 2% of the value of all secondary market transactions, as a redistribution of royalties'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>
                <span className="font-bold">50%</span> Discount merchandise
              </div>
              <Tooltip
                content={
                  'YourBabyPearl will have an online store where all community members – regardless if they own an NFT or not – can purchase her unique items and other branded products. NFT holders will be able to purchase exclusive YBP merchandise at prices discounted between 15% and 50% from listed price'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>
                <span className="font-bold">30%</span> Discount on streaming
                platforms
              </div>
              <Tooltip
                content={
                  'YourBabyPearl will provide NFT holders with discounts to her show on her favorite streaming platforms. We love our collaborations with all of the streaming sites that we work with and we respect their work. This is why most of the time during the month you will find YourBabyPearl there, but hey, who’s got the big discount? Yes, you!'
                }
              />
            </li>
          </ul>
        </div>
      </InView>
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('gold');
          }
        }}
        className="w-full flex lg:justify-center "
      >
        <div
          className={`w-full flex lg:justify-center ${
            observing !== 'gold' ? 'opacity-40' : 'opacity-100'
          } sm:opacity-100 ml-[-20px] sm:ml-0`}
        >
          <ul className="nft-box nft-textbox py-9 px-6 font-normal">
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Weekly stream show </div>
              <Tooltip
                content={
                  'Get to chat with YourBabyPearl in her super wet weekly stream shows! The stream will contain meet & greet, Q&A and full porn show'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Monthly stream party </div>
              <Tooltip
                content={
                  'This monthly event will make you take all the towels from the house next to you. Yes! That hot and wet. Basically, this is the ultimate stream show! YourBabyPearl takes this very personally :) Are you ready? We know you are not.'
                }
              />
            </li>
            <li className="text-white text-md relative mb-6 flex justify-between items-baseline gap-[11px]">
              <div>Access to premium media gallery</div>
              <Tooltip
                content={
                  'Access to all paid content which is published on all YourBabyPearl’s accounts on other pay-per-view websites, only with your NFT'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Access to NFT exclusive media gallery</div>
              <Tooltip
                content={
                  'Only the hottest videos ranging from amateur to super-pro productions that you’ll get to see only with NFT authentication. These productions are made exclusively for the NFT holders to see. You will never see this content anywhere else. Yes, it’s unique for the NFT holders!'
                }
              />
            </li>

            <li className="text-white text-md relative my-6 flex justify-between gap-[11px] items-center h-[27px]">
              -
            </li>

            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>
                Passive income (<strong>2%</strong> of all secondary market
                transactions)
              </div>
              <Tooltip
                content={
                  'Earn a share of royalties just for holding an NFT! NFT holders will receive 2% of the value of all secondary market transactions, as a redistribution of royalties'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>
                <span className="font-bold">25%</span> Discount merchandise
              </div>
              <Tooltip
                content={
                  'YourBabyPearl will have an online store where all community members – regardless if they own an NFT or not – can purchase her unique items and other branded products. NFT holders will be able to purchase exclusive YBP merchandise at prices discounted between 15% and 50% from listed price'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>
                <span className="font-bold">20%</span> Discount on streaming
                platforms
              </div>
              <Tooltip
                content={
                  'YourBabyPearl will provide NFT holders with discounts to her show on her favorite streaming platforms. We love our collaborations with all of the streaming sites that we work with and we respect their work. This is why most of the time during the month you will find YourBabyPearl there, but hey, who’s got the big discount? Yes, you!'
                }
              />
            </li>
          </ul>
        </div>
      </InView>
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('sugar');
          }
        }}
        className="w-full flex lg:justify-center "
      >
        {({ inView, ref, entry }) => (
          <div
            className={`w-full flex lg:justify-center ${
              observing !== 'sugar' ? 'opacity-40' : 'opacity-100'
            } sm:opacity-100 ml-[-40px] sm:ml-0`}
            ref={ref}
          >
            <ul className="nft-box nft-textbox py-9 px-6 font-normal">
              <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
                <div>Weekly stream show </div>
                <Tooltip
                  content={
                    'Get to chat with YourBabyPearl in her super wet weekly stream shows! The stream will contain meet & greet, Q&A and full porn show'
                  }
                />
              </li>
              <li className="text-white text-md relative my-6 flex justify-between items-center h-[27px] gap-[11px]">
                -
              </li>
              <li className="text-white text-md relative mb-6 flex justify-between items-baseline gap-[11px]">
                <div>Access to premium media gallery</div>
                <Tooltip
                  content={
                    'Access to all paid content which is published on all YourBabyPearl’s accounts on other pay-per-view websites, only with your NFT'
                  }
                />
              </li>
              <li className="text-white text-md relative my-6 flex justify-between items-center h-[51px] gap-[11px]">
                -
              </li>

              <li className="text-white text-md relative my-6 flex justify-between items-center h-[27px] gap-[11px]">
                -
              </li>

              <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
                <div>
                  Passive income (<strong>2%</strong> of all secondary market
                  transactions)
                </div>
                <Tooltip
                  content={
                    'Earn a share of royalties just for holding an NFT! NFT holders will receive 2% of the value of all secondary market transactions, as a redistribution of royalties'
                  }
                />
              </li>
              <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
                <div>
                  <span className="font-bold">15%</span> Discount merchandise
                </div>
                <Tooltip
                  content={
                    'YourBabyPearl will have an online store where all community members – regardless if they own an NFT or not – can purchase her unique items and other branded products. NFT holders will be able to purchase exclusive YBP merchandise at prices discounted between 15% and 50% from listed price'
                  }
                />
              </li>
              <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
                <div>
                  <span className="font-bold">15%</span> Discount on streaming
                  platforms
                </div>
                <Tooltip
                  content={
                    'YourBabyPearl will provide NFT holders with discounts to her show on her favorite streaming platforms. We love our collaborations with all of the streaming sites that we work with and we respect their work. This is why most of the time during the month you will find YourBabyPearl there, but hey, who’s got the big discount? Yes, you!'
                  }
                />
              </li>
            </ul>
          </div>
        )}
      </InView>
    </div>
  );
  const contentBoxesBlue = (
    <div
      className="flex items-stretch max-w-screen-xl"
      style={{ width: `${containerWidth}px` }}
    >
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('master');
          }
        }}
        className="w-full flex lg:justify-center "
      >
        <div
          className={`w-full flex lg:justify-center ${
            observing !== 'master' ? 'opacity-40' : 'opacity-100'
          } sm:opacity-100`}
        >
          <ul className="nft-box nft-textbox py-9 px-6 font-normal">
            <li className="text-white text-md relative mb-6 flex justify-between items-baseline gap-[11px]">
              <div>Token based services</div>
              <Tooltip
                content={
                  'We will be launching our own token soon, that will give you access to our VIP content (e.g. spy on YourBabyPearl 24/7 for one full week on our Big Brother 360 stream show, give tips during shows) and more. This will be our utility and transaction token for all our roadmap projects!'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div className="tracking-tight">
                Discounts/Whitelisting on upcoming projects
              </div>
              <Tooltip
                content={
                  'YourBabyPearl has planned a roadmap full of exciting projects inside the brand, creating the most advanced web3 ecosystem in the adult industry. NFT holders will benefit from special offers and perks related to upcoming projects'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Access to metaverse</div>
              <Tooltip
                content={
                  'YourBabyPearl’s Metaverse will be the web3.0 place to access interactive VR adult content that will encompass all YourBabyPearl brand content, but will also be open for third party content from other performers, as well as a place for industry conferences, events, meetings, networking'
                }
              />
            </li>

            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Perks and benefits in YBP's metaverse </div>
              <Tooltip
                content={
                  'You will get free tickets to exclusive shows in the metaverse, access to the founders’ offices for business opportunities and more!'
                }
              />
            </li>
          </ul>
        </div>
      </InView>
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('gold');
          }
        }}
        className="w-full flex lg:justify-center "
      >
        <div
          className={`w-full flex lg:justify-center ${
            observing !== 'gold' ? 'opacity-40' : 'opacity-100'
          } sm:opacity-100 ml-[-20px] sm:ml-0`}
        >
          <ul className="nft-box nft-textbox py-9 px-6 font-normal">
            <li className="text-white text-md relative mb-6 flex justify-between items-baseline gap-[11px]">
              <div>Token based services</div>
              <Tooltip
                content={
                  'We will be launching our own token soon, that will give you access to our VIP content (e.g. spy on YourBabyPearl 24/7 for one full week on our Big Brother 360 stream show, give tips during shows) and more. This will be our utility and transaction token for all our roadmap projects!'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div className="tracking-tight">
                Discounts/Whitelisting on upcoming projects
              </div>
              <Tooltip
                content={
                  'YourBabyPearl has planned a roadmap full of exciting projects inside the brand, creating the most advanced web3 ecosystem in the adult industry. NFT holders will benefit from special offers and perks related to upcoming projects'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Access to metaverse</div>
              <Tooltip
                content={
                  'YourBabyPearl’s Metaverse will be the web3.0 place to access interactive VR adult content that will encompass all YourBabyPearl brand content, but will also be open for third party content from other performers, as well as a place for industry conferences, events, meetings, networking'
                }
              />
            </li>
            <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
              <div>Perks and benefits in YBP's metaverse </div>
              <Tooltip
                content={
                  'You will get free tickets to exclusive shows in the metaverse, access to the founders’ offices for business opportunities and more!'
                }
              />
            </li>
          </ul>
        </div>
      </InView>
      <InView
        threshold={0.4}
        onChange={(inView, entry) => {
          if (inView) {
            setObserving('sugar');
          }
        }}
        className="w-full flex lg:justify-center"
      >
        {({ inView, ref, entry }) => (
          <div
            className={`w-full flex lg:justify-center ${
              observing !== 'sugar' ? 'opacity-40' : 'opacity-100'
            } sm:opacity-100 ml-[-40px] sm:ml-0`}
            ref={ref}
          >
            <ul className="nft-box nft-textbox py-9 px-6 font-normal">
              <li className="text-white text-md relative mb-6 flex justify-between items-baseline gap-[11px]">
                <div>Token based services</div>
                <Tooltip
                  content={
                    'We will be launching our own token soon, that will give you access to our VIP content (e.g. spy on YourBabyPearl 24/7 for one full week on our Big Brother 360 stream show, give tips during shows) and more. This will be our utility and transaction token for all our roadmap projects!'
                  }
                />
              </li>
              <li className="text-white text-md relative my-6 flex items-center h-[42px]">
                -
              </li>
              <li className="text-white text-md relative my-6 flex justify-between items-baseline gap-[11px]">
                <div>Access to metaverse</div>
                <Tooltip
                  content={
                    'YourBabyPearl’s Metaverse will be the web3.0 place to access interactive VR adult content that will encompass all YourBabyPearl brand content, but will also be open for third party content from other performers, as well as a place for industry conferences, events, meetings, networking'
                  }
                />
              </li>
              <li className="text-white text-md relative my-6 flex items-center h-[42px]">
                -
              </li>
            </ul>
          </div>
        )}
      </InView>
    </div>
  );

  return (
    <div
      className="w-full relative"
      style={{ backgroundImage: `url('/img/Bg_Tokenomics.png')` }}
    >
      <header className="flex flex-col space-y-3 text-center my-8 relative px-4">
        <h1 className="text-secondary text-title">
          {data ? data.utilities_title : 'NFT utilities and benefits'}
        </h1>
        <p className="text-secondary text-subtitle max-w-screen-md mx-auto">
          {data
            ? data.utilities_title
            : 'We have three types of NFTs that grant you different kinds of access. By buying an NFT, you become part of our growing business and community'}
        </p>
      </header>
      <section
        className="section-wrapper pr-0 sm:pr-4 relative flex flex-col items-center justify-center mb-8 text-white z-10 overflow-x-auto scroll-smooth"
        ref={scrollContainer}
      >
        {nftBoxes}
        <div
          className="flex lg:w-max content-boxes-pink lg:justify-center mx-auto relative"
          style={{ minWidth: `${containerWidth}px` }}
        >
          <p className="text-xl font-bold rotate-text text-center sm:ml-4 lg:ml-0">
            Ready on Launch
          </p>
          {contentBoxes}
          <p className="text-xl font-bold rotate-text text-center">
            Ready on Launch
          </p>
        </div>
        <div
          className="flex lg:w-max content-boxes-blue my-5 lg:justify-center mx-auto relative"
          style={{ minWidth: `${containerWidth}px` }}
        >
          <p className="text-xl font-bold rotate-text text-center sm:ml-4 lg:ml-0">
            On Roadmap
          </p>
          {contentBoxesBlue}
          <p className="text-xl font-bold rotate-text text-center">
            On Roadmap
          </p>
        </div>
      </section>
      <img
        className="absolute left-0 bottom-0 z-0 w-full h-[90%] tokenomics-flare"
        src="/img/Flare_bg_tokenomics.png"
        alt="YBP Flare"
      />
    </div>
  );
}
