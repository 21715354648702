import PlayCircleIcon from '../svgs/PlayCircleIcon'

const data = [
  {
    id: 1,
    title: 'Blockchain',
    desc: 'Besides this NFT project, we will launch two more NFT collections with improved benefits with other fresh porn stars. After all projects are launched, you will be able to merge all 3 NFTs into one mega porn NFT that will have more benefits in all our future projects.',
  },

  {
    id: 2,
    title: 'Online Investments',
    desc: 'Investments will be directed to develop and optimize the content website to provide full benefits to members as fast as possible and of the best quality, as well as to build further Web3.0 projects: adult NFT marketplace, adult social and content platform, YourBabyPearl metaverse and others!',
  },

  {
    id: 3,
    title: 'YourBabyPearl Brand',
    desc: 'Budget will also be allocated for the development of the YourBabyPearl brand by increasing production quality, launching new lines of merch and signing new partnerships, for annual events around the globe and exclusive member benefits, as well as developing the first adult franchise system exclusively dedicated and supported by YourBabyPearl',
  },
]

const chartsData = [
  {
    id: 1,
    title: 'Developers and collaborators',
    img: '/img/DevelopersChart.svg',
    percentage: '12',
  },

  {
    id: 2,
    title: 'Blockchain',
    img: '/img/BlockchainChart.svg',
    percentage: '25',
  },

  {
    id: 3,
    title: 'Online Investments',
    img: '/img/InvestmentChart.svg',
    percentage: '40',
  },

  {
    id: 4,
    title: 'YBP Brand',
    img: '/img/BrandChart.svg',
    percentage: '23',
  },
]

export default function InvestmentPlan() {
  return (
    <section className="space-y-10 xl:space-y-20 xl:mt-[-150px]">
      <h1 className="text-title text-secondary text-center">Investment plan</h1>

      <div
        className="bg-cover sm:py-10 xl:py-20 flex relative bg-blend-multiply"
        style={{
          backgroundImage: "url('/img/InvestmentPlanBg.png')",
          backgroundColor: 'rgba(0, 0, 0, .8)',
        }}
      >
        <div className="px-4 flex flex-col-reverse xl:flex-row justify-between items-center gap-20 xl:gap-0 mx-auto max-w-[1500px]">
          <div className="flex flex-row">
            <div className="px-6 lg:px-10"></div>

            <div className="flex flex-col gap-8 flex-1">
              {data.map(item => (
                <div
                  key={item.id}
                  className="relative text-left space-y-2 text-secondary before:content-[''] before:w-[2px] before:bg-[#691D44] before:absolute before:left-[-36px] before:h-full first:before:top-[33px] last:before:top-[-88px] last:before:h-[90px] before:z-10"
                >
                  <div className="flex gap-4 items-center">
                    <div className="ml-[-52px] z-20 bg-black">
                      <PlayCircleIcon />
                    </div>
                    <h2 className="font-bold">{item.title}</h2>
                  </div>

                  <p className="relative text-base lg:text-sm max-w-[480px]">
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-row justify-center items-baseline xl:justify-end flex-1 flex-wrap gap-10 xl:gap-20 lg:mr-12 max-w-[300px] sm:max-w-full">
            {chartsData.map(item => (
              <div
                key={item.id}
                className="flex flex-col shrink-0 sm:w-full basis-[130px] sm:basis-[300px] lg:basis-[400px] xl:basis-60 items-center justify-center gap-4"
              >
                <div className="relative">
                  <img src={item.img} alt={`${item.title} Chart`} />
                  <span className="absolute top-[55px] left-[45px] sm:top-[110px] sm:left-[90px] text-secondary text-2xl sm:text-4xl text-center">
                    {item.percentage}%
                  </span>
                </div>

                {/* <div className="hidden sm:block relative">
                  <img src={item.img} alt={`${item.title} Chart`} />
                  <span className="absolute top-[110px] left-[90px] text-secondary text-4xl text-center">
                    {item.percentage}%
                  </span>
                </div> */}

                <h2 className="text-secondary text-center text-md sm:text-lg sm:text-xl">
                  {item.title}
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
