export default function PlayCircleIcon() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M17.6 2.6001C25.8 2.6001 32.5 9.3 32.5 17.5C32.5 25.7 25.8 32.3999 17.6 32.3999C9.39995 32.3999 2.69995 25.7 2.69995 17.5C2.69995 9.3 9.29995 2.6001 17.6 2.6001ZM17.6 0.600098C8.29995 0.600098 0.699951 8.2 0.699951 17.5C0.699951 26.8 8.29995 34.3999 17.6 34.3999C26.9 34.3999 34.5 26.8 34.5 17.5C34.5 8.2 26.9 0.600098 17.6 0.600098Z"
        fill="#D33A88"
      />
      <path
        d="M13.2 17.5V11.1001L18.7 14.2998L24.2 17.5L18.7 20.7002L13.2 23.8999V17.5Z"
        fill="#D33A88"
      />
    </svg>
  )
}
