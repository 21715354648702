export default function MuteIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 57 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 13L10.9633 23.602H3V37.2451H10.7763L24 48V13Z"
        fill="white"
        stroke="white"
        strokeWidth="5"
        strokeLinejoin="round"
      />
      <path
        d="M36 21.4733C37.7895 24.1548 38.7428 27.2924 38.7428 30.5C38.7428 33.7076 37.7895 36.8452 36 39.5268M42.0898 15.4835C45.3498 19.8336 47.1085 25.0959 47.1085 30.5C47.1085 35.9041 45.3498 41.1664 42.0898 45.5165M47.6649 10C52.4123 15.8123 55 23.0444 55 30.5C55 37.9556 52.4123 45.1877 47.6649 51"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M47.5 2L16 58.5" stroke="white" strokeWidth="5" />
    </svg>
  )
}
