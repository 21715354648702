export default function DiscordIcon() {
  return (
    <svg
      width={37}
      height={28}
      viewBox="0 0 37 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.99971 21.7002C0.39971 15.3002 2.79976 8.4998 6.29976 3.2998C6.39976 3.1998 6.49966 3.1 6.69966 3C8.89966 2 11.1997 1.2999 13.5997 0.899902C13.7997 0.899902 13.8997 0.900098 13.9997 1.1001C14.1997 1.6001 14.4997 2 14.6997 2.5C14.7997 2.8 14.9997 2.7998 15.1997 2.7998C17.6997 2.5998 20.0997 2.5998 22.5997 2.7998C22.7997 2.7998 22.9997 2.8 23.0997 2.5C23.2997 2 23.4998 1.6001 23.7998 1.1001C23.8998 0.900098 23.9997 0.899902 24.1997 0.899902C26.5997 1.2999 28.7997 2 30.9997 3C31.0997 3.1 31.2997 3.1998 31.3997 3.2998C31.9997 4.2998 32.6997 5.2999 33.1997 6.3999C35.2997 10.3999 36.3997 14.8999 36.5997 19.3999C36.6997 20.5999 36.6997 21.9001 36.4997 23.1001C36.4997 23.3001 36.3998 23.5 36.2998 23.5C33.8998 25.3 31.1998 26.7001 28.2998 27.6001C27.4998 27.9001 27.4997 27.9002 26.9997 27.2002C26.4997 26.4002 25.9997 25.5998 25.4997 24.7998C26.0997 24.5998 26.5997 24.4001 26.9997 24.1001C27.4997 23.9001 27.9997 23.5998 28.4997 23.2998C27.9997 22.6998 27.8998 22.6999 27.2998 22.8999C21.6998 25.2999 15.9997 25.2999 10.3997 22.8999C9.79973 22.6999 9.59966 22.6998 9.19966 23.2998C10.1997 23.7998 11.1997 24.2998 12.1997 24.7998C11.5997 25.7998 10.9997 26.7001 10.3997 27.6001C10.2997 27.8001 10.0997 27.7998 9.89973 27.7998C6.89973 26.7998 3.99973 25.4 1.39973 23.5C1.29973 23.4 1.19966 23.2001 1.19966 23.1001C1.09966 22.6001 1.09971 22.2002 0.99971 21.7002ZM27.8997 15.6001C27.8997 15.5001 27.8997 15.3001 27.8997 15.1001C27.8997 14.9001 27.7997 14.5999 27.6997 14.3999C26.8997 12.1999 24.2997 11.4 22.6997 13C21.1997 14.4 21.1997 16.9999 22.6997 18.3999C23.8997 19.5999 25.6997 19.5998 26.8997 18.2998C27.5997 17.5998 27.8997 16.7001 27.8997 15.6001ZM16.0997 15.7002C15.9997 15.3002 15.9998 14.7999 15.7998 14.3999C15.0998 12.1999 12.4997 11.4999 10.8997 12.8999C9.29973 14.2999 9.29976 17 10.7998 18.5C11.9998 19.6 13.7997 19.5999 14.9997 18.3999C15.7997 17.5999 16.0997 16.7002 16.0997 15.7002Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8998 15.5999C27.8998 16.6999 27.5998 17.5996 26.8998 18.2996C25.6998 19.4996 23.8997 19.4997 22.6997 18.3997C21.1997 16.9997 21.1997 14.3998 22.6997 12.9998C24.2997 11.3998 26.8997 12.1997 27.6997 14.3997C27.7997 14.5997 27.7998 14.8999 27.8998 15.0999C27.8998 15.2999 27.8998 15.4999 27.8998 15.5999Z"
        fill="#CE2C80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0998 15.7C15.9998 16.7 15.6998 17.5997 14.9998 18.3997C13.7998 19.5997 12.0999 19.5998 10.7999 18.4998C9.19986 17.0998 9.29984 14.2997 10.8998 12.8997C12.5998 11.3997 15.0999 12.1997 15.7999 14.3997C15.9999 14.7997 16.0998 15.2 16.0998 15.7Z"
        fill="#CE2C80"
      />
    </svg>
  )
}
