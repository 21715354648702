import Modal from 'react-modal'
import { useState } from 'react'

export default function WarningModal({ isOpen, closeModal }) {
  const [isUnder, setIsUnder] = useState(false)

  Modal.setAppElement('#root')

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #D02E80',
      maxWidth: '90%',
      padding: '3rem',
      background: 'rgba(193, 0, 132, 0.25)',
    },
  }

  function Button({ children, className, onClick }) {
    return (
      <button
        onClick={() => onClick()}
        className={`text-lg border border-[#D02E80] rounded-[999999px] p-1 w-max font-medium min-w-max ${
          className ? className : ''
        }`}
      >
        <div className="py-2 px-4 border border-[#D02E80] rounded-[999999px] hover:bg-[#D02E80] hover:text-white transition-all ease-out duration-100">
          {children}
        </div>
      </button>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className="flex flex-col items-center space-y-12 text-black py-4 max-w-[490px] text-white min-h-[256px]">
        <p className="text-2xl sm:text-3xl text-center sm:leading-10">
          Please confirm that you are over the age of 18 to view and use this
          website.
        </p>

        {isUnder ? (
          <div className="font-bold flex rounded-lg">
            <div className="bg-[#d32c81] border border-[#d32c81] px-3 py-1 text-xl flex items-center rounded-tl-md rounded-bl-md">
              !
            </div>
            <div className="border border-[#d32c81] rounded-tr-md rounded-br-md px-3 py-1 flex items-center">
              You are not old enough to view this content
            </div>
          </div>
        ) : (
          <div className="flex gap-4 flex-wrap items-center justify-center w-full">
            <Button
              className={'flex-1'}
              onClick={() => {
                setIsUnder(true)
              }}
            >
              I'M UNDER 18
            </Button>

            <Button className={'flex-1'} onClick={closeModal}>
              I'M OVER 18
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}
