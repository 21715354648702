const data = [
  {
    id: 7,
    img: '/img/diff-7.png',
    description:
      'For investors, this NFT provides passive income to all holders. In addition, we have an investment plan in a real life brand that will maximize investor return (e.g. brand build up, airdrops, rewards, etc.)',
    height: 55,
    width: 55,
  },
  {
    id: 3,
    img: '/img/diff-3.png',
    description:
      'We deliver premium adult photo and video content, from live stream porn parties and 360 wild streams, to voyeur and fetish surprises',
    height: 105,
    width: 105,
  },
  {
    id: 4,
    img: '/img/diff-4.png',
    description:
      'We are launching an NFT collection with real utilities, many of which will be live at the moment of public minting',
    height: 56,
    width: 56,
  },
  {
    id: 1,
    img: '/img/diff-1.png',
    description:
      'Buying a NFT from the collection is your ticket to the future of porn',
    height: 100,
    width: 100,
  },
  {
    id: 5,
    img: '/img/diff-5.png',
    description:
      'We have a business approach to the project: we have a clear roadmap, we have an investment plan and we will report our progress to our investors along the line',
    height: 95,
    width: 95,
  },
  {
    id: 6,
    img: '/img/diff-6.png',
    description:
      'We are innovating in the industry, but at the same time creating new use cases for blockchain smart contracts',
    height: 100,
    width: 100,
  },
  {
    id: 8,
    img: '/img/diff-8.png',
    description:
      'The success of this project in the adult industry will be transferred to the blockchain with a network effect. This type of project can be applied to any business in any industry',
    height: 140,
    width: 140,
  },
  {
    id: 2,
    img: '/img/diff-2.png',
    description:
      'We are creating a safe environment for the adult industry performers and our members',
    height: 77,
    width: 77,
  },
]

export default function WhatMakesUsDifferent() {
  return (
    <section className="section-wrapper flex flex-col items-center justify-center space-y-20">
      <header className="text-title text-secondary text-center">
        <h1>What makes us different</h1>
      </header>

      <div className="flex flex-col items-center justify-center md:flex-row flex-wrap gap-16 max-w-[1400px] mx-auto">
        {data.map(item => (
          <div key={item.id}>
            <div className="flex flex-col max-w-[450px] md:w-[300px] md:h-[350px] gap-4 relative items-center justify-start z-20 pb-4 md:pb-0">
              <img
                src="/img/GradientBlue.png"
                alt=""
                className="absolute top-[-100px] md:top-[-60px] md:bottom-40 z-10"
              />

              <img
                src={item.img}
                alt="Image"
                height={item.height}
                width={item.width}
                className="z-30"
              />

              <p className="text-secondary text-center z-20 leading-[20px]">
                {item.description}
              </p>
            </div>
            <div
              className="h-[2px] w-full bg-cover"
              style={{
                backgroundImage: "url('/img/BorderGradient.png')",
              }}
            ></div>
          </div>
        ))}
      </div>
    </section>
  )
}
