import BtnJoinDiscord from './Buttons/BtnJoinDIscord'

export default function JoinDiscordBanner() {
  return (
    <section className="section-wrapper lg:max-w-[90%]">
      <div
        className="bg-cover bg-no-repeat flex flex-wrap gap-8 items-center justify-center md:justify-between px-4 lg:px-8 py-4 rounded-lg border-2 border-[#6F124C]"
        style={{
          background:
            'linear-gradient(90deg, rgba(147,42,96,1) 0%, rgba(81,0,55,1) 0%, rgba(103,22,64,1) 23%, rgba(17,12,70,1) 71%, rgba(79,3,60,1) 100%, rgba(116,9,121,1) 100%)',
        }}
      >
        <header className="text-center md:text-left">
          <h1 className="text-2xl sm:text-3xl text-secondary">
            Take the leap to
            <br />
            THE FUTURE OF PORN
          </h1>
        </header>

        <BtnJoinDiscord />
      </div>
    </section>
  )
}
