export default function ClosePlayerIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        fill="#D32C81"
        fill-opacity="0.5"
        stroke="#D32C81"
      />
      <path
        d="M13.7226 16.7998L11.8922 14.0478L10.2794 16.7998H7.79615L10.6762 12.2302L7.73215 7.8142H10.2794L12.0842 10.5278L13.6714 7.8142H16.1546L13.3002 12.3454L16.2698 16.7998H13.7226Z"
        fill="white"
      />
    </svg>
  )
}
