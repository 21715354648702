export default function PrevIcon() {
  return (
    <svg
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 15.866C6.83333 15.4811 6.83333 14.5189 7.5 14.134L27 2.87564C27.6667 2.49074 28.5 2.97187 28.5 3.74167L28.5 26.2583C28.5 27.0281 27.6667 27.5093 27 27.1244L7.5 15.866Z"
        fill="white"
      />
      <rect
        x="4"
        y="27"
        width="4"
        height="24"
        rx="1"
        transform="rotate(180 4 27)"
        fill="white"
      />
    </svg>
  )
}
