export default function ReplayIcon() {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83333 21.0933C4.39078 19.5133 2 16.0358 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 16.0358 19.6092 19.5133 16.1667 21.0933L14.9167 21.5833"
        stroke="white"
        strokeWidth="2.7274"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.3958 15.4539L12.6296 22.5887L19.7644 25.3548"
        stroke="white"
        strokeWidth="2.7274"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}
