export default function NextIcon() {
  return (
    <svg
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5 14.134C29.1667 14.5189 29.1667 15.4811 28.5 15.866L9 27.1244C8.33333 27.5093 7.5 27.0281 7.5 26.2583L7.5 3.74167C7.5 2.97187 8.33333 2.49074 9 2.87564L28.5 14.134Z"
        fill="white"
      />
      <rect x="32" y="3" width="4" height="24" rx="1" fill="white" />
    </svg>
  )
}
